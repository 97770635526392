.loader_main {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.loader {
  width: fit-content;
  font-weight: bold;
  font-family: roboto;
  font-size: 30px;
  background: radial-gradient(circle closest-side, #000 94%, #0000)
    right/calc(200% - 1em) 100%;
  animation: l24 1s infinite alternate linear;
}
.loader::before {
  content: 'Pi Network Hub';
  line-height: 1em;
  color: #0000;
  background: inherit;
  background-image: radial-gradient(circle closest-side, #fff 94%, #000);
  -webkit-background-clip: text;
  background-clip: text;
}

@keyframes l24 {
  100% {
    background-position: left;
  }
}

// body {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   margin: 0;
//   background-color: #f0f0f0;
// }
.logo {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: linear-gradient(135deg, #8a348e 0%, #423f88 50%, #333 100%);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.outer-ring {
  width: 300px;
  height: 300px;
  border: 15px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  position: absolute;
}
.inner-ring {
  width: 250px;
  height: 250px;
  border: 5px dashed rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  position: absolute;
}
.text {
  color: white;
  font-family: Arial, sans-serif;
  font-size: 24px;
  text-align: center;
  max-width: 60%;
}
