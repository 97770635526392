.circledark {
  position: absolute;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 0px 1px 0px #ffffff;
}

.circlelight {
  position: absolute;
  border-radius: 50%;
  background: #bdbdbd;
  box-shadow: 0px 0px 1px 0px #bdbdbd;
}

/* Base sizes for mobile */
.small {
  width: 40vw;
  height: 40vw;
  left: -13vw;
  bottom: -13vw;
}

.medium {
  width: 66vw;
  height: 66vw;
  left: -26vw;
  bottom: -26vw;
}

.large {
  width: 93vw;
  height: 93vw;
  left: -40vw;
  bottom: -40vw;
}

.xlarge {
  width: 120vw;
  height: 120vw;
  left: -53vw;
  bottom: -53vw;
}

.xxlarge {
  width: 146vw;
  height: 146vw;
  left: -66vw;
  bottom: -66vw;
}

/* Tablet sizes */
@media (min-width: 600px) {
  .small {
    width: 33vw;
    height: 33vw;
    left: -13vw;
    bottom: -13vw;
  }

  .medium {
    width: 46vw;
    height: 46vw;
    left: -20vw;
    bottom: -20vw;
  }

  .large {
    width: 60vw;
    height: 60vw;
    left: -26vw;
    bottom: -26vw;
  }

  .xlarge {
    width: 86vw;
    height: 86vw;
    left: -40vw;
    bottom: -40vw;
  }

  .xxlarge {
    width: 113vw;
    height: 113vw;
    left: -53vw;
    bottom: -53vw;
  }
}

/* Desktop sizes */
@media (min-width: 1024px) {
  .small {
    width: 20vw;
    height: 20vw;
    left: -8vw;
    bottom: -8vw;
  }

  .medium {
    width: 35vw;
    height: 35vw;
    left: -15vw;
    bottom: -15vw;
  }

  .large {
    width: 50vw;
    height: 50vw;
    left: -23vw;
    bottom: -23vw;
  }

  .xlarge {
    width: 66vw;
    height: 66vw;
    left: -31vw;
    bottom: -31vw;
  }

  .xxlarge {
    width: 85vw;
    height: 85vw;
    left: -39vw;
    bottom: -39vw;
  }
}

/* Shades */
.shade1 {
  opacity: 0.1;
}

.shade2 {
  opacity: 0.2;
}

.shade3 {
  opacity: 0.3;
}

.shade4 {
  opacity: 0.4;
}

.shade5 {
  opacity: 0.5;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.8);
  }
}
