.container__slider {
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: black;

  > button {
    background-color: transparent;
    outline: none;
    border: 0;
    color: whitesmoke;
    font-size: 24px;

    &:active {
      color: black;
    }
  }

  .slider__btn-next {
    position: absolute;
    right: 0;
  }

  .slider__btn-prev {
    position: absolute;
    left: 0;
  }

  .container__slider__links {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);

    .container__slider__links-small {
      position: relative;
      width: 8px;
      height: 8px;
      padding: 0;
      border-radius: 50%;
      outline: none;
      border: 0;
      background-color: white;
      border: 1px solid grey;
      transition: background-color 1s ease;
      margin: 0 2px;

      &-active {
        background-color: black;
      }
    }
  }

  .slider__item {
    box-sizing: border-box;
    min-width: 100%;
    height: auto;
    overflow: hidden;
    object-fit: cover;
    transform: translateX(0);
    transition: transform 1s ease;

    &-active-1 {
      transform: translateX(0);
    }

    &-active-2 {
      transform: translateX(-100%);
    }

    &-active-3 {
      transform: translateX(-200%);
    }

    &-active-4 {
      transform: translateX(-300%);
    }

    &-active-5 {
      transform: translateX(-300%);
    }

    &-active-6 {
      transform: translateX(-300%);
    }

    &-active-7 {
      transform: translateX(-300%);
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
