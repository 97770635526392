.particle-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100vh;
}
.particle {
  position: absolute;
  background: #eeeeee;
  border-radius: 50%;
}
.line {
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
  transform-origin: 0 0;
  opacity: 0.4;
}
.line::before,
.line::after {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  background: #eeeeee;
  border-radius: 50%;
}
.line::before {
  left: -2.5px;
  top: -2px;
}
.line::after {
  right: -2.5px;
  bottom: -2px;
}

.particle_dark {
  position: absolute;
  background: #757575;
  border-radius: 50%;
}
.line_dark {
  position: absolute;
  background: rgba(117, 117, 117, 0.4);
  transform-origin: 0 0;
  opacity: 0.4;
}
.line_dark::before,
.line_dark::after {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  background: #757575;
  border-radius: 50%;
}
.line_dark::before {
  left: -2.5px;
  top: -2px;
}
.line_dark::after {
  right: -2.5px;
  bottom: -2px;
}
